import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import Cards from 'components/Core/Cards';
import CardsGroup from 'components/Core/Cards/CardsGroup';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';

const ComparisonCardSection = ({ entries, componentCollection }) => {
  const title = getEntry('title', entries);
  const description = getEntry('subtitle', entries);
  const cards = componentCollection?.[0].components;

  return (
    <SectionComparisonCard>
      <div className="section-comparison-card--container">
        <ContentfulRichText richText={title} />
        <ContentfulRichText richText={description} />
        <div className="section-comparison-card--content">
          {cards?.length > 0 && (
            <CardsGroup cols={3} responsiveMode={'twocolumn'}>
              {cards.map((cardItem, index) => (
                <Cards
                  bodyType="comparison"
                  company={cardItem.title}
                  ctaTitle="Learn more"
                  ctaLink={cardItem.ctaLink}
                  key={index}
                />
              ))}
            </CardsGroup>
          )}
        </div>
      </div>
    </SectionComparisonCard>
  );
};

ComparisonCardSection.propTypes = {
  title: PropTypes.string,
  awards: PropTypes.array,
};

const SectionComparisonCard = styled.div`
  background: var(--white);

  .section-comparison-card--container {
    padding: 80px 28px;
    max-width: 990px;
    margin: 0 auto;
    text-align: center;

    .section-comparison-card--title {
      letter-spacing: var(--letter-spacing--0-04);
      color: var(--darkest);
    }

    .section-comparison-card--description {
      font-size: var(--font-size-20);
      color: var(--dark);
      padding: 0;
      margin: 24px 0 0;
    }

    .section-comparison-card--content {
      margin-top: 35px;
    }
  }

  @media (max-width: 767px) {
    .section-comparison-card--container {
      padding: 48px 28px;

      .section-comparison-card--title {
        font-size: var(--font-size-28);
        letter-spacing: var(--letter-spacing--0-03);
      }

      .section-comparison-card--content {
        margin-top: 17px;
      }
    }
  }

  @media (max-width: 575px) {
    .section-comparison-card--container {
      padding: 48px 20px;

      .card--body-wrapper.icon-card--body-wrapper {
        padding-bottom: 65px !important;
      }

      .section-comparison-card--content {
        margin-top: 17px;
      }
    }
  }
`;

export default ComparisonCardSection;
